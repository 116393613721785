// at times we've had multiple GTM
const dataLayerNames = ['dataLayer'];

let lastDataString = '';
let lastTime = new Date();
export default function pushToDataLayers(data) {
  // help prevent double tracking
  const dataString = JSON.stringify(data);
  if (dataString === lastDataString) {
    const now = new Date();
    if (now - lastTime < 1000) {
      console.log('pushToDataLayers() called multiple times - repeat event not sent:', data);
      return;
    }
  }
  lastDataString = dataString;
  lastTime = new Date();
  dataLayerNames.forEach(dln => {
    window[dln] = window[dln] || [];
    window && window[dln].push(data);
    if (typeof window !== 'undefined' && window.location.host !== 'placeit.net') {
      console.log('GTM data layer push (not shown in prod):', dln, data);
    }
  });
}
