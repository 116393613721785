/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import * as React from "react";

export const _010920251500021020252050Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_010920251500021020252050() {
  return React.useContext(_010920251500021020252050Context);
}

export default _010920251500021020252050Context;
/* prettier-ignore-end */
