/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import * as React from "react";

export const RegionContext = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function useRegion() {
  return React.useContext(RegionContext);
}

export default RegionContext;
/* prettier-ignore-end */
