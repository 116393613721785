import React, {memo} from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import {withRouter} from 'next/router';
import {getCookie} from '../lib/utils/cookies';
import VariantContextPlasmic, {VariantContext as vc, ContextNames} from './VariantContextPlasmic';

let DynamicVarSelModal;
if (typeof window !== 'undefined' && window.location.host !== 'placeit.net')
  DynamicVarSelModal = dynamic(() => import('../components/Modal/VariantSelectionModal'), {
    ssr: false,
  });

export const VariantContext = vc;

export const revmoeProjectFromVariantName = name => {
  return name.replace(/(.*)____placeit.*$/g, '$1');
};

// plasmic variant names always get capitalized.
const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/*
VariantContext These contexts deal generally with variants, which could be:
 1. the variants we show for AB test experiments
 2. global variants such as subscriber level
 3. scheduled variants
 4. other stuff in the future

 AB test variants are set directly via cookies or props.
 Other variant types may use helpers to set their values.
*/

// used to give provider children a consistant child reference
const StableChild = memo(
  class extends React.Component {
    render() {
      if (typeof window !== 'undefined' && window.location.host !== 'placeit.net')
        console.log('StableChild Render', Date.now());
      return this.props.children;
    }
  }
);


class VariantProvider extends React.Component {
  constructor(props) {
    super(props);
    /* variations can come from a few places:
      props - passed in by tests
      query - set by middleware from cookie. used by server rendered pages
      cookie - for staticly built pages, we read cookie on clientside
      state/context - setup by helpers, used to show variants such as subscriber level
    */
    const cookieVariations = this.parseExperimentId(getCookie('experiment_id'));
    const queryVariations = this.parseExperimentId(props?.query?.experiment_id);
    const propVariations = props.variations;
    const variations = Object.entries({...propVariations, ...queryVariations, ...cookieVariations}).reduce((accumulator, currentValue) => {
      accumulator[capitalizeFirstLetter(currentValue[0])] = currentValue[1];
      return accumulator;
    }, {});

    this.state = {};

    this.setProjectVariants(variations, true);

    this.state.setVariations = (variations = {}) => {
      if (typeof window !== 'undefined' && window.location.host !== 'placeit.net')
        console.log('setVariations', variations);
      this.setProjectVariants(variations);
    };
  }

  componentDidMount() {
    this._isMounted = true;
    // You can now safely run code that depends on mounting
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getHelperPrerenderValue(name, val) {
    this.props.helpers?.forEach(helper => {
      if (!helper.props.prerender) return;
      val = helper.props.prerender(name, val, helper?.props?.data) || val;
    });
    return val;
  }

  setProjectVariants(variations, helpers=false, construct=false, contextNames = ContextNames) {
    // resolves variations names like ctalevel 
    // to contextNames like CTALevel____placeit-ce-pages
    for (const contextName of contextNames) {
      let name = contextName.split('____')[0];
      let val =  variations[name]
        || variations[String(name).toLowerCase()]
        || this.state[name]
        || this.state[String(name).toLowerCase()]          
        || null;
      // helpers can advise on variant value before render
      if (helpers)  val = this.getHelperPrerenderValue(name, val);
      if (!this._isMounted) {
        this.state[name] = val;
        this.state[contextName] = val;  // easier to use in our code
        this.state[String(name).toLowerCase()] = val; // covers some legacy uses
      } else {
        this.setState({[name]: val});
        this.setState({[contextName]: val});  // easier to use in our code
        this.setState({[String(name).toLowerCase()]: val}); // covers some legacy uses
      }
    }
  }

  parseExperimentId(id = '') {
    const variations = {};
    id.split('!').forEach(expVar => {
      let [experiment, variant] = expVar.split(':');
      if (!experiment || !variant) return;
      // Plasmic does this to make the names safe for JS keys
      if (typeof variant === 'string' && /^\d/.test(variant)) {
        variant = '_' + variant;
      }
      // lowercase keys to deal with plasmic case inconsistency
      variations[String(experiment)] = variant;
    });
    return variations;
  }

  render() {
    return (
      <VariantContextPlasmic {...this.state}>
        <StableChild>{this.props.children}</StableChild>
        {this.props.helpers}
        {DynamicVarSelModal && <DynamicVarSelModal {...this.state} />}
      </VariantContextPlasmic>
    );
  }
}

VariantProvider.propTypes = {
  children: PropTypes.node.isRequired,
  variations: PropTypes.object,
  globalVariants: PropTypes.object,
  helpers: PropTypes.array,
};

VariantProvider.defaultProps = {
  variations: undefined,
  globalVariants: undefined,
  helpers: undefined,
};

export const VariantProviderWithoutRouter = VariantProvider;
export default withRouter(VariantProvider);
