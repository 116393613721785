import {track as trackGTM, page as pageGTM} from './gtm/gtm-manager';
import {
  isMarketingCookieEnabled,
  isBlacklistedEvent,
  isStatisticsCookieEnabled,
} from '../utils/cookiesPolicy';

const trackEventQueue = [];

const analyticsProviders = new Set();
let analyticsReady = false;
let routerBound = false;
let amplitudeLoadingPromise = null;

// Moved outside bindRouter to keep a stable reference.
// This avoids creating a new function on each call,
// so `router.events.off` can properly remove the listener.
const handleRouteChange = url => {
  analyticsProviders.forEach(provider => provider.page(url));
  pageGTM();
};

async function loadAnalytics(router) {
  const loaders = [];
  // if we ever need to load multiple systems again...
  // loaders.push(loadSegment());
  if (isMarketingCookieEnabled() && isStatisticsCookieEnabled()) {
    loaders.push(loadAmplitude());
  }

  return Promise.all(loaders).then(() => {
    console.log('all analytics providers loaded');
    analyticsReady = true;
    bindRouter(router);
    flushTrackEventQueue();
  });
}

/*
async function loadSegment() {
  const SegmentAnalytics = await import('./segment');
  analyticsProviders.push(SegmentAnalytics.default);
}
*/

async function loadAmplitude() {
  if (amplitudeLoadingPromise) {
    console.log('[Tracker] Amplitude is already loading');
    return amplitudeLoadingPromise;
  }

  amplitudeLoadingPromise = (async () => {
    console.log('[Tracker] Importing Amplitude...');
    const AmplitudeAnalytics = await import('./amplitude');

    if (analyticsProviders.has(AmplitudeAnalytics.default)) {
      return;
    }

    await AmplitudeAnalytics.default.getInitPromise();
    await AmplitudeAnalytics.default.identify();
    analyticsProviders.add(AmplitudeAnalytics.default);

    console.log('[Tracker] Amplitude initialized and identified');
  })();

  return amplitudeLoadingPromise;
}

async function bindRouter(router) {
  if (routerBound) {
    console.log('[Tracker] Router already bound – skipping');
    return;
  }
  routerBound = true;
  // track first page load:
  handleRouteChange(window.location.href);
  // failsafe to prevent double tracking
  router.events.off('routeChangeComplete', handleRouteChange);
  // on mount
  router.events.on('routeChangeComplete', handleRouteChange);
  return () => {
    // on unmount
    router.events.off('routeChangeComplete', handleRouteChange);
    routerBound = false;
  };
}

function trackEvent(label, properties = {}) {
  if (!label) {
    console.error('ERROR: trackEvent() requires a label argument.');
    return;
  }
  if (typeof window !== 'undefined' && window.location.host !== 'placeit.net') {
    console.log('trackEvent (not shown in prod):', label, properties);
  }
  // check for blacklisted events
  if (isMarketingCookieEnabled() === false && isBlacklistedEvent(label)) {
    if (typeof window !== 'undefined' && window.location.host !== 'placeit.net') {
      console.log('blacklisted Event (not shown in prod):', label, properties);
    }
    return;
  }

  if (analyticsReady) {
    analyticsProviders.forEach(provider => provider.track(label, properties));
    trackGTM(label, properties);
  } else {
    trackEventQueue.push({label, properties});
  }
}

function flushTrackEventQueue() {
  trackEventQueue.forEach(({label, properties}) => {
    trackEvent(label, properties);
  });
}

export {trackEvent, loadAnalytics, analyticsProviders, loadAmplitude};
