// NavBar events:
export const MAINMENU_CLICKED = 'marketing:mainmenu:clicked'; // components/NavBar/MenuBar/CategoryMenu/CategoryItem
export const EMAIL_MAINMENU_CLICKED = 'email:mainmenu:clicked'; // components/NavBar/MenuBar/CategoryMenu/CategoryItem
export const SUBMENU_CLICKED = 'marketing:submenu:clicked'; // components/NavBar/MenuBar/CategoryMenu/CategorySubMenuLinks
export const EMAIL_SUBMENU_CLICKED = 'email:submenu:clicked'; // components/NavBar/MenuBar/CategoryMenu/CategorySubMenuLinks
export const PRICEBUTTON_CLICK = 'PricingModal:Stillshots:Opens'; // components/NavBar/MenuBar/PricingButton.jsx
export const MOBILEMENU_CLICKED = 'marketing:mobilemenu:clicked'; // components/NavBar/Mobile/CategoryMenuPanel/CategorySubMenuDropdown
export const EMAIL_MOBILEMENU_CLICKED = 'email:mobilemenu:clicked'; // components/NavBar/Mobile/CategoryMenuPanel/CategorySubMenuDropdown
export const CTABLOCK_SEEN = 'marketing:ctablock:seen'; // components/Subscription/CtaPrice.jsx
export const EMAIL_CTABLOCK_SEEN = 'email:ctablock:seen'; // components/Subscription/CtaPrice.jsx

export const SEARCH_FEW_RESULTS = 'search:few.results'; // components/NavBar/SearchBar/AutoComplete/AutoCompleteContainer
export const SEARCH_NO_RESULTS = 'search:no.results'; // components/NavBar/SearchBar/AutoComplete/AutoCompleteContainer
export const SEARCH_SUCCESS = 'search:success'; // components/NavBar/SearchBar/SearchBarEventReporter
export const EMAIL_SEARCH_SUCCESS = 'email:search:success'; // components/NavBar/SearchBar/SearchBarEventReporter
export const SEARCH_TERM_COUNT_ERROR = 'search:error:GetSearchTermCount';
export const STAGE_VALIDATION_ERROR = 'stage:error:validation'; // lib/utils/hasValidAlgoliaHits.js

export const NAME_SUBMITED = 'LogoMaker:NameSubmitted'; // components/LogoMaker/Steps/SelectLogo/SelectLogo.machine.js
export const SELECT_INDUSTRY = 'LogoMaker:SelectIndustry'; // components/LogoMaker/Steps/SelectLogo/SelectLogo.machine.js
export const SELECT_GRAPHIC = 'LogoMaker:SelectGraphic'; // components/LogoMaker/Steps/SelectLogo/SelectLogo.machine.js
export const SELECT_LOGO = 'LogoMaker:SelectLogo'; // components/LogoMaker/LogoMaker.machine.js
export const SELECT_FONT = 'LogoMaker:SelectFont'; // components/LogoMaker/LogoMaker.machine.js
export const SELECT_COLOR = 'LogoMaker:SelectColor'; // components/LogoMaker/LogoMaker.machine.js
export const SHOW_MORE = 'LogoMaker:ShowMore'; // components/LogoMaker/Steps/Common/Grid.jsx
export const CHOSE_LOGO = 'LogoMaker:ChoseLogo'; // components/LogoMaker/Steps/Common/EditBtn.jsx && components/LogoMaker/Steps/Common/FinishButton.jsx
export const MOVE_TO_FONTS = 'LogoMaker:ToFonts'; // components/LogoMaker/Steps/SelectLogo/index.jsx
export const MOVE_TO_COLORS = 'LogoMaker:ToColors'; // components/LogoMaker/Steps/Fonts/index.jsx
export const MOVE_BACK = 'LogoMaker:Back'; // components/LogoMaker/Steps/Colors/index.jsx && components/LogoMaker/Steps/Fonts/index.jsx

// Logo maker processing time events
export const LOGO_PROCESSING_CHANGES = 'LogoMaker:Processing:Changes';
export const LOGO_PROCESSING_VARIATIONS = 'LogoMaker:Processing:Variations';

// Favorites events
export const STAGE_BOOKMARKED = 'BookmarkedStageEvent:Bookmark';
export const STAGE_UNBOOKMARKED = 'BookmarkedStageEvent:Unbookmark';
export const EMAIL_BOOKMARKED = 'email:StageBookmarked';

// CE Hero Dropdown
export const CE_DROPDOWN_CLICKED = 'marketing:dropdown:clicked'; // components/Discovery/MenuSelect/CeHeroDropdown.jsx
export const EMAIL_CE_DROPDOWN_CLICKED = 'email:dropdown:clicked'; // components/Discovery/MenuSelect/CeHeroDropdown.jsx

// Background Remover events
export const BG_REMOVAL_END = 'aiModel:bgRemovalEnd';
export const BG_REMOVAL_ERROR = 'aiModel:bgRemovalError';
export const BG_REMOVAL_RATE = 'aiModel:bgRemovalRate';
export const BG_REMOVAL_DOWNLOAD = 'Download:freeStage';

// PDP pages
export const PDP_EDIT = 'productDescription:edit';
export const PDP_OPENED = 'PDP:modal:opens';
export const PDP_CLOSED = 'PDP:modal:close';

// SortBy Filters for SelectWithAlgoliaSortBy
export const SORTBY_POPULAR_CLICKED = 'sortby:popular:clicked';
export const SORTBY_BESTSELLING_CLICKED = 'sortby:bestSelling:clicked';
export const SORTBY_NEWEST_CLICKED = 'sortby:newest:clicked';

// HeaderStrip events: components/Campaigns/HeaderStrips/
export const HEADERSTRIP = {};

export const NAVBAR_LOGO_CLICKED = 'navbar:logo:clicked'; // components/NavBar/MenuBar/Logo.jsx

// Cookie Manager
export const COOKIE_MANAGER_MODAL_OPEN = 'cookieManager:modal:opens';
export const COOKIE_MANAGER_POLICY_CLICKED = 'cookieManager:cookiePolicy:clicked';
export const COOKIE_MANAGER_ACCEPT_ALL_CLICKED = 'cookieManager:acceptAll:clicked';
export const COOKIE_MANAGER_CONFIRM_CLICKED = 'cookieManager:confirm:clicked';
export const COOKIE_MANAGER_PREFERENCES_TOGGLE = 'cookieManager:preferences:toggle';
export const COOKIE_MANAGER_STATISTICS_TOGGLE = 'cookieManager:statistics:toggle';
export const COOKIE_MANAGER_MARKETING_TOGGLE = 'cookieManager:marketing:toggle';

export const NO_RESULTS_BEST_SELLING_CLICK = 'No Results:bestSelling:clicked'; // components/Discovery/NoResultsPage.jsx

// Sharing
export const SHARE_MODAL_OPENED = 'pdp:shareModal:opened';
export const SOCIAL_SHARE = 'pdp:shareModal:clicked';
export const CLIPBOARD_SHARE = 'share:click';

// Login
export const GOOGLE_ONE_TAP_ERROR = 'google_one_tap:error';
export const GOOGLE_ONE_TAP_SUCCESS = 'google_one_tap:success';
export const EMAIL_LOGIN = 'email:user:login';
export const USER_LOGIN = 'user:login';
export const CLIENT_LOGIN = 'user:login:client';
export const CLIENT_SIGNUP = 'user:signup:client';

// For You
export const FOR_YOU_SHOW_MORE = 'forYou:showMoreCTA:clicked';
