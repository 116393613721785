import {useContext, useEffect, useCallback} from 'react';
import {useRouter} from 'next/router';
import {UserContext} from '../../context/UserContext';
import {unsubscribeMailingListsUser} from '../../lib/utils/unsubscribeMailingListsUser';
import {isMarketingCookieEnabled} from '../../lib/utils/cookiesPolicy';
import {loadAnalytics} from '../../lib/analytics/tracker';

export default function CookiebotManager() {
  const {currentUser} = useContext(UserContext);
  const router = useRouter();

  const runAnalyticsIfNeeded = useCallback(() => {
    loadAnalytics(router);
  }, [router]);

  const consentAction = useCallback(() => {
    if (!isMarketingCookieEnabled() && currentUser?.loggedIn) {
      unsubscribeMailingListsUser();
    }
    runAnalyticsIfNeeded();
  }, [currentUser?.loggedIn, runAnalyticsIfNeeded]);

  useEffect(() => {
    runAnalyticsIfNeeded();
  }, [runAnalyticsIfNeeded]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    window.addEventListener('CookiebotOnConsentReady', consentAction);

    return () => {
      window.removeEventListener('CookiebotOnConsentReady', consentAction);
    };
  }, [consentAction]);

  return null;
}
