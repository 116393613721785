/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import * as React from "react";

export const _032720241400041620240600Context = React.createContext(
  "PLEASE_RENDER_INSIDE_PROVIDER"
);

export function use_032720241400041620240600() {
  return React.useContext(_032720241400041620240600Context);
}

export default _032720241400041620240600Context;
/* prettier-ignore-end */
