import Script from 'next/script';

const gtmConsentScript = `
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }

  gtag("consent", "default", {
    'ad_personalization': 'denied',
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'analytics_storage': 'denied',
    'functionality_storage': 'denied',
    'personalization_storage': 'denied',
    'region': ['GB','AT','BE','BG','CH','CY','DE','DK','EE','ES','FI','FR','GG','GR','HR','HU','IE','IM','IT','JE','LT','LU','LV','MT','NL','PL','PT','RO','SE','SI','SK','CA','US-CA'],
    'wait_for_update': 500
  });

  gtag('consent', 'default', {
    'ad_personalization': 'granted',
    'ad_storage': 'granted',
    'ad_user_data': 'granted',
    'analytics_storage': 'granted',
    'functionality_storage': 'granted',
    'personalization_storage': 'granted',
    'security_storage': 'granted',
    'wait_for_update': 500,
  });

  gtag("set", "ads_data_redaction", true);
  gtag("set", "url_passthrough", false);
`;

function GTMConsent() {
  return (
    <Script
      id="google-consent-mode"
      data-cookieconsent="ignore"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{__html: gtmConsentScript}}
    />
  );
}

export default GTMConsent;
